import React from 'react';
import {Route, Routes} from "react-router-dom";
import {rootPaths} from "./rootPaths";
import {rootRoutes} from "./rootRoutes";

function RootRouter() {
  return (
    <Routes>
      {rootRoutes.map((item, index) => (
        <Route path={item.path} element={<item.element/>} key={index}/>
      ))}
    </Routes>
  );
}

export default RootRouter;