import React from 'react';
import TeamControl from "./team-control/TeamControl";
import CityDevelopment from "./city-development/CityDevelopment";
import CityEvolution from "./city-evolution/CityEvolution";
import DownloadApp from "./download-app/DownloadApp";

function Main() {
  return (
    <div>
      <TeamControl/>
      <CityDevelopment/>
      <CityEvolution/>
      <DownloadApp/>
    </div>
  );
}

export default Main;