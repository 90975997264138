import React from 'react';
import Iphone from '../../../assets/images/iphone.png'
import styles from './index.module.scss'

function CityEvolution() {
  return (
    <div className={styles.city_evolution}>
      <div className={styles.city_evolution_left}>
        <img src={Iphone} alt=""/>
      </div>
      <div className={styles.city_evolution_right}>
        <h3>Biz birgalikda shahrimizni obodonlashtiramiz!</h3>
        <p>
          Muammo haqida xabar berish oson, faqat uch bosqichda:
          <br/>
          1. Hududni tanlang
          <br/>
          2. Muammoli mavzuni tanlang
          <br/>
          3. Rasm yuklang va muammoni tasvirlab bering
        </p>
      </div>
    </div>
  );
}

export default CityEvolution;