import React from 'react';

import {BrowserRouter} from "react-router-dom";

import styles from './app.module.scss'
import './styles/main.scss'
import RootRouter from "./routing/root/RootRouter";

function App() {
  return (
    <div className={styles.app}>
      <div className={styles.container}>
        <BrowserRouter>
          <RootRouter/>
        </BrowserRouter>
      </div>
    </div>
  );
}

export default App;
