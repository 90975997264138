import React from 'react';
import Logo from '../../../assets/images/logo.png'
import TeamControls from '../../../assets/images/TeamControl.png'

import AppStore from "../../../assets/icons/AppStore";
import GooglePlay from "../../../assets/icons/GooglePlay";
import styles from './index.module.scss'

function TeamControl() {
  return (
    <div className={styles.team}>
      <div className={styles.team_header}>
        <img src={Logo} alt=""/>
        <div className={`${styles.radius} ${styles.radius_right}`}>
          <div></div>
        </div>
        <div className={`${styles.radius} ${styles.radius_bottom}`}>
          <div></div>
        </div>
      </div>
      <div className={styles.team_body}>
        <div className={styles.team_body_left}>
          <h3>
            "Faol Fuqaro" shahrimizni obodonlashtirishga yordam beradi!
          </h3>
          <p>
            Shahar hayotidagi muammolar haqida xabar bering va rasmiy javoblarni oling.
          </p>
          <div className={styles.buttons}>
            <a href="https://play.google.com/store/apps/details?id=uz.softex.faolfuqaro" target="_blank">
              <GooglePlay/>
            </a>
            <a href="https://apps.apple.com/us/app/faol-fuqaro/id6444103642" target="_blank">
              <AppStore/>
            </a>
          </div>
        </div>
        <div className={styles.team_body_right}>
          <img src={TeamControls} alt=""/>
        </div>
      </div>
    </div>
  );
}

export default TeamControl;