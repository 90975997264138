import React from 'react';

function AppStore() {
    return (
        <svg width="208" height="60" viewBox="0 0 208 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M206.844 53.0804C206.844 56.2779 204.197 58.8679 200.921 58.8679H7.08636C3.81275 58.8679 1.15555 56.2779 1.15555 53.0804V6.92712C1.15555 3.73116 3.81275 1.13208 7.08636 1.13208H200.92C204.197 1.13208 206.843 3.73116 206.843 6.92712L206.844 53.0804Z"
                fill="black"/>
            <path
                d="M200.296 1.20188C203.863 1.20188 206.765 4.0275 206.765 7.5V52.5C206.765 55.9725 203.863 58.7981 200.296 58.7981H7.7037C4.13689 58.7981 1.23452 55.9725 1.23452 52.5V7.5C1.23452 4.0275 4.13689 1.20188 7.7037 1.20188H200.296ZM200.296 3.8147e-06H7.7037C3.46859 3.8147e-06 0 3.37688 0 7.5V52.5C0 56.6231 3.46859 60 7.7037 60H200.296C204.531 60 208 56.6231 208 52.5V7.5C208 3.37688 204.531 3.8147e-06 200.296 3.8147e-06Z"
                fill="#A6A6A6"/>
            <path
                d="M46.4194 29.676C46.3748 24.8415 50.4855 22.4895 50.6734 22.38C48.3454 19.0755 44.737 18.624 43.4689 18.588C40.4383 18.2775 37.4986 20.3535 35.9547 20.3535C34.3801 20.3535 32.0027 18.618 29.4405 18.669C26.1433 18.7185 23.0587 20.577 21.367 23.463C17.8757 29.3475 20.4795 37.995 23.8245 42.7515C25.4977 45.081 27.4529 47.682 30.0121 47.5905C32.5158 47.49 33.451 46.0365 36.4724 46.0365C39.4661 46.0365 40.3443 47.5905 42.9543 47.532C45.6414 47.49 47.3331 45.192 48.9478 42.8415C50.8814 40.1715 51.658 37.542 51.6888 37.407C51.6256 37.386 46.4703 35.4705 46.4194 29.676Z"
                fill="white"/>
            <path
                d="M41.4891 15.459C42.8357 13.8195 43.757 11.589 43.5013 9.3255C41.5522 9.4095 39.1148 10.638 37.7112 12.2415C36.4693 13.6545 35.36 15.9705 35.6466 18.1485C37.836 18.3075 40.0839 17.073 41.4891 15.459Z"
                fill="white"/>
            <path
                d="M75.5733 15.0135C75.5733 16.779 75.0295 18.108 73.9432 19.0005C72.9371 19.824 71.5073 20.2365 69.6554 20.2365C68.7371 20.2365 67.9513 20.1975 67.2934 20.1195V10.473C68.1516 10.338 69.076 10.269 70.0744 10.269C71.8386 10.269 73.1682 10.6425 74.065 11.3895C75.0695 12.234 75.5733 13.4415 75.5733 15.0135ZM73.8708 15.057C73.8708 13.9125 73.5596 13.035 72.9371 12.423C72.3147 11.8125 71.4056 11.5065 70.2085 11.5065C69.7 11.5065 69.2671 11.5395 68.9081 11.6085V18.942C69.1068 18.972 69.4705 18.9855 69.9989 18.9855C71.2346 18.9855 72.1883 18.651 72.8601 17.982C73.5319 17.313 73.8708 16.338 73.8708 15.057Z"
                fill="white"/>
            <path
                d="M84.6005 16.5555C84.6005 17.643 84.2816 18.534 83.6437 19.233C82.9751 19.9515 82.0891 20.31 80.9829 20.31C79.9167 20.31 79.0677 19.9665 78.4345 19.2765C77.8028 18.588 77.4869 17.7195 77.4869 16.6725C77.4869 15.5775 77.812 14.679 78.4653 13.9815C79.1186 13.284 79.9968 12.9345 81.1031 12.9345C82.1693 12.9345 83.0259 13.278 83.6746 13.9665C84.2908 14.6355 84.6005 15.4995 84.6005 16.5555ZM82.9258 16.6065C82.9258 15.954 82.7809 15.3945 82.4928 14.928C82.1538 14.364 81.6716 14.082 81.0445 14.082C80.3959 14.082 79.9028 14.364 79.5639 14.928C79.2742 15.3945 79.1309 15.963 79.1309 16.635C79.1309 17.2875 79.2757 17.847 79.5639 18.3135C79.9136 18.8775 80.4005 19.1595 81.0291 19.1595C81.6454 19.1595 82.1292 18.873 82.4774 18.2985C82.7763 17.823 82.9258 17.259 82.9258 16.6065Z"
                fill="white"/>
            <path
                d="M96.7046 13.0785L94.432 20.1495H92.9529L92.0115 17.079C91.7727 16.3125 91.5785 15.5505 91.4276 14.7945H91.3983C91.2581 15.5715 91.0639 16.332 90.8143 17.079L89.8144 20.1495H88.3183L86.1813 13.0785H87.8407L88.6619 16.44C88.8607 17.235 89.024 17.9925 89.155 18.7095H89.1842C89.3044 18.1185 89.5032 17.3655 89.7836 16.455L90.8143 13.08H92.1301L93.1177 16.383C93.3566 17.1885 93.5507 17.964 93.7001 18.711H93.7448C93.8542 17.9835 94.0191 17.208 94.2379 16.383L95.1192 13.08H96.7046V13.0785Z"
                fill="white"/>
            <path
                d="M105.075 20.1495H103.461V16.0995C103.461 14.8515 102.974 14.2275 101.997 14.2275C101.518 14.2275 101.131 14.3985 100.831 14.742C100.533 15.0855 100.382 15.4905 100.382 15.954V20.148H98.7676V15.099C98.7676 14.478 98.7476 13.8045 98.7091 13.0755H100.128L100.204 14.181H100.248C100.436 13.8375 100.717 13.554 101.085 13.3275C101.522 13.0635 102.012 12.93 102.549 12.93C103.227 12.93 103.79 13.143 104.239 13.5705C104.797 14.094 105.075 14.8755 105.075 15.9135V20.1495Z"
                fill="white"/>
            <path d="M109.528 20.1495H107.915V9.83398H109.528V20.1495Z" fill="white"/>
            <path
                d="M119.035 16.5555C119.035 17.643 118.716 18.534 118.078 19.233C117.409 19.9515 116.522 20.31 115.417 20.31C114.349 20.31 113.5 19.9665 112.869 19.2765C112.237 18.588 111.921 17.7195 111.921 16.6725C111.921 15.5775 112.246 14.679 112.899 13.9815C113.553 13.284 114.431 12.9345 115.536 12.9345C116.603 12.9345 117.458 13.278 118.109 13.9665C118.725 14.6355 119.035 15.4995 119.035 16.5555ZM117.358 16.6065C117.358 15.954 117.213 15.3945 116.925 14.928C116.588 14.364 116.104 14.082 115.479 14.082C114.828 14.082 114.335 14.364 113.998 14.928C113.708 15.3945 113.565 15.963 113.565 16.635C113.565 17.2875 113.71 17.847 113.998 18.3135C114.348 18.8775 114.835 19.1595 115.463 19.1595C116.079 19.1595 116.562 18.873 116.91 18.2985C117.21 17.823 117.358 17.259 117.358 16.6065Z"
                fill="white"/>
            <path
                d="M126.849 20.1495H125.399L125.279 19.335H125.234C124.738 19.9845 124.031 20.31 123.113 20.31C122.427 20.31 121.873 20.0955 121.455 19.6695C121.076 19.2825 120.887 18.801 120.887 18.2295C120.887 17.3655 121.256 16.707 122 16.251C122.743 15.795 123.788 15.5715 125.133 15.582V15.45C125.133 14.5185 124.631 14.0535 123.624 14.0535C122.908 14.0535 122.276 14.229 121.731 14.577L121.403 13.545C122.078 13.1385 122.911 12.9345 123.894 12.9345C125.792 12.9345 126.744 13.9095 126.744 15.8595V18.4635C126.744 19.17 126.78 19.7325 126.849 20.1495ZM125.173 17.7195V16.629C123.392 16.599 122.501 17.0745 122.501 18.054C122.501 18.423 122.603 18.699 122.811 18.8835C123.019 19.068 123.284 19.1595 123.6 19.1595C123.954 19.1595 124.285 19.05 124.587 18.8325C124.891 18.6135 125.077 18.336 125.147 17.9955C125.164 17.919 125.173 17.826 125.173 17.7195Z"
                fill="white"/>
            <path
                d="M136.024 20.1495H134.591L134.516 19.014H134.471C134.014 19.878 133.234 20.31 132.139 20.31C131.263 20.31 130.535 19.9755 129.957 19.3065C129.379 18.6375 129.091 17.769 129.091 16.7025C129.091 15.558 129.404 14.631 130.032 13.923C130.641 13.263 131.387 12.933 132.274 12.933C133.249 12.933 133.932 13.2525 134.32 13.893H134.351V9.83398H135.967V18.2445C135.967 18.933 135.986 19.5675 136.024 20.1495ZM134.351 17.1675V15.9885C134.351 15.7845 134.336 15.6195 134.306 15.4935C134.215 15.1155 134.02 14.7975 133.722 14.541C133.422 14.2845 133.06 14.1555 132.642 14.1555C132.04 14.1555 131.569 14.388 131.222 14.8545C130.878 15.321 130.704 15.9165 130.704 16.644C130.704 17.343 130.869 17.91 131.2 18.3465C131.55 18.8115 132.021 19.044 132.612 19.044C133.142 19.044 133.565 18.8505 133.887 18.462C134.199 18.1035 134.351 17.6715 134.351 17.1675Z"
                fill="white"/>
            <path
                d="M149.834 16.5555C149.834 17.643 149.515 18.534 148.877 19.233C148.208 19.9515 147.324 20.31 146.216 20.31C145.152 20.31 144.303 19.9665 143.668 19.2765C143.036 18.588 142.72 17.7195 142.72 16.6725C142.72 15.5775 143.045 14.679 143.699 13.9815C144.352 13.284 145.23 12.9345 146.338 12.9345C147.403 12.9345 148.261 13.278 148.908 13.9665C149.524 14.6355 149.834 15.4995 149.834 16.5555ZM148.161 16.6065C148.161 15.954 148.016 15.3945 147.728 14.928C147.387 14.364 146.907 14.082 146.278 14.082C145.631 14.082 145.138 14.364 144.797 14.928C144.508 15.3945 144.364 15.963 144.364 16.635C144.364 17.2875 144.509 17.847 144.797 18.3135C145.147 18.8775 145.634 19.1595 146.263 19.1595C146.879 19.1595 147.364 18.873 147.712 18.2985C148.01 17.823 148.161 17.259 148.161 16.6065Z"
                fill="white"/>
            <path
                d="M158.516 20.1495H156.903V16.0995C156.903 14.8515 156.416 14.2275 155.438 14.2275C154.958 14.2275 154.572 14.3985 154.273 14.742C153.974 15.0855 153.824 15.4905 153.824 15.954V20.148H152.208V15.099C152.208 14.478 152.19 13.8045 152.151 13.0755H153.569L153.644 14.181H153.689C153.878 13.8375 154.159 13.554 154.526 13.3275C154.965 13.0635 155.453 12.93 155.991 12.93C156.667 12.93 157.231 13.143 157.679 13.5705C158.239 14.094 158.516 14.8755 158.516 15.9135V20.1495Z"
                fill="white"/>
            <path
                d="M169.383 14.256H167.605V17.691C167.605 18.564 167.921 19.0005 168.546 19.0005C168.836 19.0005 169.076 18.9765 169.266 18.927L169.307 20.1195C168.988 20.2365 168.569 20.295 168.053 20.295C167.415 20.295 166.919 20.106 166.56 19.728C166.2 19.35 166.021 18.714 166.021 17.8215V14.256H164.959V13.0785H166.021V11.7825L167.603 11.3175V13.077H169.381V14.256H169.383Z"
                fill="white"/>
            <path
                d="M177.931 20.1495H176.315V16.1295C176.315 14.862 175.828 14.2275 174.853 14.2275C174.104 14.2275 173.592 14.595 173.312 15.33C173.264 15.4845 173.236 15.6735 173.236 15.8955V20.148H171.623V9.83398H173.236V14.0955H173.267C173.776 13.32 174.504 12.933 175.449 12.933C176.117 12.933 176.671 13.146 177.11 13.5735C177.657 14.106 177.931 14.898 177.931 15.945V20.1495Z"
                fill="white"/>
            <path
                d="M186.749 16.2795C186.749 16.5615 186.727 16.7985 186.688 16.992H181.846C181.868 17.691 182.099 18.2235 182.547 18.5925C182.957 18.9225 183.485 19.0875 184.132 19.0875C184.849 19.0875 185.502 18.9765 186.091 18.753L186.343 19.845C185.655 20.136 184.844 20.2815 183.906 20.2815C182.781 20.2815 181.895 19.959 181.254 19.314C180.61 18.669 180.291 17.8035 180.291 16.7175C180.291 15.651 180.589 14.763 181.188 14.055C181.814 13.299 182.659 12.921 183.727 12.921C184.772 12.921 185.565 13.299 186.101 14.055C186.534 14.655 186.749 15.3975 186.749 16.2795ZM185.208 15.873C185.22 15.4065 185.114 15.0045 184.895 14.6655C184.615 14.229 184.188 14.01 183.61 14.01C183.083 14.01 182.653 14.223 182.325 14.6505C182.057 14.991 181.898 15.3975 181.846 15.873H185.208Z"
                fill="white"/>
            <path
                d="M82.653 47.2559H79.154L77.2373 41.3924H70.5752L68.7494 47.2559H65.3428L71.9433 27.2939H76.0201L82.653 47.2559ZM76.6595 38.9324L74.9262 33.7199C74.7429 33.1874 74.3993 31.9334 73.8924 29.9594H73.8307C73.6289 30.8084 73.3038 32.0624 72.857 33.7199L71.1545 38.9324H76.6595Z"
                fill="white"/>
            <path
                d="M99.6274 39.882C99.6274 42.33 98.9479 44.265 97.589 45.6855C96.3718 46.95 94.8603 47.5815 93.0561 47.5815C91.1086 47.5815 89.7096 46.9005 88.8576 45.5385H88.796V53.121H85.5111V37.6005C85.5111 36.0615 85.4695 34.482 85.3894 32.862H88.2783L88.4616 35.1435H88.5232C89.6187 33.4245 91.2812 32.5665 93.5122 32.5665C95.2563 32.5665 96.7123 33.237 97.8771 34.5795C99.045 35.9235 99.6274 37.6905 99.6274 39.882ZM96.2809 39.999C96.2809 38.598 95.9573 37.443 95.3071 36.534C94.5968 35.586 93.6431 35.112 92.4475 35.112C91.6371 35.112 90.9006 35.376 90.2427 35.8965C89.5833 36.4215 89.1519 37.107 88.95 37.956C88.8483 38.352 88.7975 38.676 88.7975 38.931V41.331C88.7975 42.378 89.1272 43.2615 89.7866 43.983C90.4461 44.7045 91.3027 45.0645 92.3566 45.0645C93.5938 45.0645 94.5568 44.5995 95.2455 43.6725C95.9357 42.744 96.2809 41.52 96.2809 39.999Z"
                fill="white"/>
            <path
                d="M116.633 39.882C116.633 42.33 115.953 44.265 114.593 45.6855C113.377 46.95 111.865 47.5815 110.061 47.5815C108.114 47.5815 106.715 46.9005 105.864 45.5385H105.803V53.121H102.518V37.6005C102.518 36.0615 102.476 34.482 102.396 32.862H105.285L105.468 35.1435H105.53C106.624 33.4245 108.286 32.5665 110.519 32.5665C112.261 32.5665 113.717 33.237 114.885 34.5795C116.049 35.9235 116.633 37.6905 116.633 39.882ZM113.286 39.999C113.286 38.598 112.961 37.443 112.311 36.534C111.6 35.586 110.65 35.112 109.453 35.112C108.641 35.112 107.906 35.376 107.246 35.8965C106.587 36.4215 106.157 37.107 105.955 37.956C105.855 38.352 105.803 38.676 105.803 38.931V41.331C105.803 42.378 106.132 43.2615 106.789 43.983C107.448 44.703 108.305 45.0645 109.362 45.0645C110.599 45.0645 111.562 44.5995 112.251 43.6725C112.941 42.744 113.286 41.52 113.286 39.999Z"
                fill="white"/>
            <path
                d="M135.645 41.658C135.645 43.356 135.04 44.7375 133.824 45.804C132.488 46.9695 130.629 47.5515 128.239 47.5515C126.033 47.5515 124.264 47.1375 122.925 46.308L123.686 43.6425C125.128 44.4915 126.71 44.9175 128.435 44.9175C129.672 44.9175 130.635 44.6445 131.327 44.1015C132.015 43.5585 132.359 42.8295 132.359 41.9205C132.359 41.1105 132.075 40.428 131.507 39.8745C130.941 39.321 129.997 38.8065 128.678 38.331C125.088 37.0275 123.295 35.118 123.295 32.607C123.295 30.966 123.923 29.6205 125.182 28.5735C126.436 27.525 128.109 27.0015 130.202 27.0015C132.068 27.0015 133.618 27.318 134.855 27.9495L134.034 30.5565C132.878 29.9445 131.572 29.6385 130.109 29.6385C128.954 29.6385 128.051 29.916 127.404 30.468C126.857 30.9615 126.583 31.563 126.583 32.2755C126.583 33.0645 126.895 33.717 127.524 34.23C128.071 34.704 129.065 35.217 130.507 35.7705C132.271 36.462 133.567 37.2705 134.4 38.1975C135.231 39.1215 135.645 40.278 135.645 41.658Z"
                fill="white"/>
            <path
                d="M146.506 35.262H142.885V42.2505C142.885 44.028 143.523 44.916 144.802 44.916C145.389 44.916 145.876 44.8665 146.261 44.7675L146.352 47.196C145.705 47.4315 144.853 47.55 143.797 47.55C142.5 47.55 141.486 47.1645 140.754 46.395C140.026 45.624 139.659 44.331 139.659 42.5145V35.259H137.502V32.859H139.659V30.2235L142.885 29.2755V32.859H146.506V35.262Z"
                fill="white"/>
            <path
                d="M162.842 39.9404C162.842 42.1529 162.192 43.9694 160.895 45.3899C159.534 46.8524 157.729 47.5814 155.478 47.5814C153.308 47.5814 151.581 46.8809 150.293 45.4799C149.005 44.0789 148.361 42.3104 148.361 40.1789C148.361 37.9484 149.023 36.1214 150.353 34.7009C151.68 33.2789 153.47 32.5679 155.721 32.5679C157.89 32.5679 159.636 33.2684 160.953 34.6709C162.214 36.0314 162.842 37.7879 162.842 39.9404ZM159.434 40.0439C159.434 38.7164 159.143 37.5779 158.553 36.6284C157.864 35.4794 156.88 34.9064 155.604 34.9064C154.284 34.9064 153.281 35.4809 152.592 36.6284C152.002 37.5794 151.711 38.7359 151.711 40.1039C151.711 41.4314 152.002 42.5699 152.592 43.5179C153.302 44.6669 154.294 45.2399 155.575 45.2399C156.829 45.2399 157.813 44.6549 158.524 43.4879C159.129 42.5204 159.434 41.3699 159.434 40.0439Z"
                fill="white"/>
            <path
                d="M173.52 35.6745C173.195 35.616 172.848 35.586 172.484 35.586C171.329 35.586 170.435 36.0105 169.807 36.861C169.26 37.611 168.985 38.559 168.985 39.7035V47.256H165.702L165.733 37.395C165.733 35.736 165.691 34.2255 165.61 32.8635H168.471L168.591 35.6175H168.682C169.028 34.671 169.575 33.909 170.324 33.3375C171.056 32.823 171.847 32.5665 172.699 32.5665C173.002 32.5665 173.276 32.5875 173.52 32.625V35.6745Z"
                fill="white"/>
            <path
                d="M188.211 39.3779C188.211 39.9509 188.172 40.4339 188.091 40.8284H178.236C178.274 42.2504 178.751 43.3379 179.666 44.0879C180.496 44.7584 181.57 45.0944 182.889 45.0944C184.348 45.0944 185.679 44.8679 186.876 44.4134L187.391 46.6334C185.992 47.2274 184.34 47.5229 182.434 47.5229C180.142 47.5229 178.342 46.8659 177.033 45.5534C175.726 44.2409 175.071 42.4784 175.071 40.2674C175.071 38.0969 175.68 36.2894 176.899 34.8479C178.174 33.3089 179.898 32.5394 182.068 32.5394C184.199 32.5394 185.812 33.3089 186.907 34.8479C187.775 36.0704 188.211 37.5824 188.211 39.3779ZM185.078 38.5484C185.1 37.6004 184.886 36.7814 184.441 36.0899C183.872 35.2004 182.998 34.7564 181.823 34.7564C180.749 34.7564 179.875 35.1899 179.208 36.0599C178.661 36.7514 178.336 37.5809 178.236 38.5469H185.078V38.5484Z"
                fill="white"/>
        </svg>

    );
}

export default AppStore;