import React from 'react';

import MobileApp from '../../../assets/images/mobileApp.png'
import Line from '../../../assets/images/line.png'

import styles from './index.module.scss'

function DownloadApp() {
  return (
    <div className={styles.download_app}>
      <div className={styles.download_app_left}>
        <img src={Line} alt="" className={styles.line}/>
        <img src={MobileApp} alt="" className={styles.app}/>
      </div>
      <div className={styles.download_app_right}>
        <h3>Dasturni yuklang</h3>
        <p>
          Siz ham Faol fuqaro jamoatchilik nazoratinini taminlash platformasidan foydalana olasiz. Dasturni yuklab
          oling. Shahrimizda yuzaga keladigan muammolar haqida tegishli joylarga xabar berish oqali shahrimizning yanada
          rivojlanishiga o’z hissangizni qo’shing.
        </p>
      </div>
    </div>
  );
}

export default DownloadApp;