import React from 'react';
import Iphone from '../../../assets/images/iphone.png'

import styles from './index.module.scss'

function CityDevelopment() {
  return (
    <div className={styles.development}>
      <div className={styles.development_left}>
        <h3>
          Shahrimiz rivoji uchun <br/> birga kurashaylik
        </h3>
        <p>
          Hovlidagi chiqindini vaqtida olib ketishmayaptimi? Svetofor ishlamayaptimi? Yo'lda chuqurlar bormi? Muammo
          haqida xabar bering va u e'tibordan chetda qolmaydi!
          “Faol Fuqaro” ilovasidan foydalanib, har bir shahar aholisi o’zi yashab turgan hududdagi muammolar (asvalt
          qilinmagan ko’chalar, chuqurlar, yorug‘lik bilan bog‘liq muammolar va h.k.) to‘g‘risida xabar berishi va
          shahar hokimyati yoki mas’ul tashkilotlar tomonidan muammolarni hal etish sifatini kuzatishi mumkin.
        </p>
      </div>
      <div className={styles.development_right}>
        <img src={Iphone} alt=""/>
      </div>
    </div>
  );
}

export default CityDevelopment;