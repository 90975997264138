import React from 'react';
import styles from './index.module.scss'


function PrivacyPolicy() {
  return (
    <div className={styles.container}>
      <div className={styles.first}>
        <h3>1. Umumiy qoidalar</h3>
        <ul>
          <li> 1. Ushbu Kelishuv bir tomondan «Faol fuqaro» elektron Servisi (keyingi oʼrinlarda – Servis) operatori va
            ikkinchi tomondan Servis Foydalanuvchisi oʼrtasidagi munosabatlarni tartibga soladi.
          </li>
          <li>
            2. Servis Operatori oʼz faoliyatida oʼzining Nizomiga, qaror bilan tasdiqlangan “Faol fuqaro” elektron
            Servisida maʼlumotlarni qayta ishlash Reglamentiga (keyingi oʼrinlarda – Reglament) va Foydalanuvchilar
            tomonidan “Faol fuqaro” elektron Servisiga yuborilayotgan maʼlumotlarni moderatsiya qilish boʼyicha yagona
            qoidalariga (keyingi oʼrinlarda – yagona moderatsiya qoidalari) va boshqa qonun hujjatlariga amal qiladi
            hamda istalgan paytda va keyinchalik Servis Foydalanuvchisini xabardor qilgan holda ushbu Kelishuv
            bandlarini oʼzgartirish, qoʼshimcha bandlar kiritish yoki bandlarini olib tashlash huquqini oʼzida saqlab
            qoladi.
          </li>
          <li>
            3. Servis Foydalanuvchisi ushbu Kelishuv shartlari bilan tanishganlik uchun shaxsan javobgar hisoblanadi.:
          </li>
          <li>
            4. Foydalanuvchi tomonidan Servis servislaridan foydalanishi, u tomonidan ushbu Kelishuv shartlarini toʼla
            hajmda qabul qilinganligini anglatadi:
          </li>
        </ul>
      </div>
      <div className={styles.second}>
        <h3>2. Kelishuvda foydalaniladigan asosiy tushunchalar:</h3>
        <h4>1. Ushbu Kelishuvda quyidagi asosiy tushunchalardan foydalaniladi:</h4>
        <ul>
          <li>
            1. Foydalanuvchilar tomonidan Servisga joʼnatiladigan axborot –Foydalanuvchilar tomonidan Servisga
            yuboriladigan xabarlar (Servisga texnik yordam koʼrsatish masalalaridan tashqari) va signallar..
          </li>
          <li>
            2. Foydalanuvchilar tomonidan Servisga joʼnatiladigan axborotni qayta ishlash – Foydalanuvchilar xabarlarini
            tahlil qilish, tekshirish, ular tomonidan yuborilgan xabarlarni Servisda chop etish (Servisga texnik yordam
            koʼrsatish masalalaridan tashqari), Foydalanuvchilar signallarini tahlil qilish va tekshirish, shuningdek
            ularning xabarlari va signallarini rad etish..
          </li>
          <li>
            3. Servis operatori – Navoiy Uy-joy kommunal xizmat koʼrsatish bosh boshqarmasining “Markaziy dispetcherlik
            xizmati” (keyingi oʼrinlarda – Operator)..
          </li>
          <li>
            4. Servis foydalanuvchisi – Servisda roʼyxatdan oʼtgan shaxslar (keyingi oʼrinlarda – Foydalanuvchi).
            Foydalanuvchi Servis funktsionallaridan muammo toʼgʼrisidagi matnli sharhlar va yoki fotomateriallarni xabar
            yoki signal shaklida yoʼnaltirish uchun foydalanishi mumkin..
          </li>
          <li>
            5. “Faol fuqaro” elektron Servisi - Internet tarmogʼida www.faol-fuqaro.uz manzilida joylashgan masʼul
            ijrochilarga jismoniy shaxslardan kelib tushayotgan xabar va signallarini koʼrib chiqish, ular bilan
            ishlashni optimallashtirish hamda hokimlik va shahar aholisi bilan oʼzaro hamkorligini oshirishga
            moʼljallangan interaktiv xizmati (keyingi oʼrinlarda – Servis) hisoblanadi. .
          </li>
          <li>
            6. Login – Servis xizmatlariga kirish uchun foydalaniladigan, foydalanuvchini Servisda aniqlashga imkon
            beradigan betakror belgilar ketma-ketligi..
          </li>
          <li>
            7. Roʼyxatdan oʼtish – Servis servislaridan foydalanish uchun imkoniyat yaratadigan Foydalanuvchi
            tomonidan shaxsiy hisobga olish qaydini yaratish jarayoni. Roʼyxatdan oʼtish uchun Foydalanuvchi oʼzining
            pasport seriyasi raqami hamda tug’ilgan sanasi kiritishi kerak..
          </li>
          <li>
            8. Аvtorizatsiyalash – Foydalanuvchi tomonidan telefon va telefon raqamga xabar ko’rinishida kelgan parolni
            kiritish natijalariga koʼra Foydalanuvchiga Servis servislaridan foydalanish boʼyicha muayyan huquqlarning
            berilishi..
          </li>
          <li>
            9. Foydalanuvchining xabari – Foydalanuvchi tomonidan Servisning “Muammo toʼgʼrisida xabar berish” boʼlimida
            joylashtirilgan, Navoiy shahar va tuman hokimliklari, ularning tuzilmasidagi boshqarma, korxona va
            tashkilotlar hamda boshqa mutasaddi boshqarma va tashkilotlar (keyingi oʼrinlarda – masʼul ijrochilar)ga
            koʼrib chiqish va javob tayyorlash uchun yoʼllanayotgan muammo toʼgʼrisidagi matnli va yoki fotomaterialli
            sharhlar. .
          </li>
          <li>
            10. Foydalanuvchilar xabarining chop etilishi – Servisda Foydalanuvchilar tomonidan umumfoydalanish uchun
            muammo toʼgʼrisidagi matnli sharhni va yoki fotomaterialni joylashtirish..
          </li>
          <li>
            11. Foydalanuvchining signali - Foydalanuvchi tomonidan Servisning “Muammo toʼgʼrisida xabar berish”
            boʼlimida joylashtirilgan, masʼul ijrochilarga koʼrib chiqish uchun yoʼllanayotgan muammo toʼgʼrisidagi
            matnli va yoki fotomaterialli sharhlar..
          </li>
          <li>
            12. Hisobga olish qaydi – uni Servisda autentifikatsiya qilish va Servis servislarida foydalanishiga
            imkoniyat yaratish uchun qoʼllaniladigan Foydalanuvchi toʼgʼrisidagi maʼlumotlar birligi..
          </li>
          <li>
            13.Blokka tushirish – Foydalanuvchi tomonidan Servisda amalga oshirilishi mumkin boʼlgan harakatlarni
            chegaralash boʼyicha texnik chora va u Foydalanuvchilar tomonidan Servisga axborot yoʼllashni vaqtinchalik
            taqiqlash shaklida ifodalanishi mumkin. .
          </li>
          <li>
            14. Masʼul ijrochilarning javobi - matn bilan sharh, shu jumladan masʼul ijrochilar tomonidan tayyorlangan
            sharh va foydalanuvchilarga Xizmat orqali yuborilgan javob (fotosuratlar ilova qilingan holda)..
          </li>
        </ul>
      </div>
      <div className={styles.third}>
        <h3>3. Kelishuv predmeti:</h3>
        <ul>
          <li>
            1. Ushbu Kelishuv mavzusi Operatorning Foydalanuvchiga Servisdan roʼyxatdan oʼtish va uning servislaridan
            foydalanish imkoniyatini yaratib berish boʼyicha majburiyatlari tashkil etadi.
          </li>
          <li>
            2. Foydalanuvchi tomonidan Servisda roʼyxatdan oʼtishi ushbu Kelishuv shartlarini toʼliq hajmda va
            istisnosiz qabul qilgan hisoblanadi. Foydalanuvchi Kelishuvning biron bir qoidasiga qoʼshilmagan taqdirda,
            Foydalanuvi Servis servislaridan foydalanishga haqli emas.
          </li>
          <li>
            3.Servis servislaridan foydalanish ushbu Kelishuv, Reglament, Yagona moderatsiya qoidalari bilan tartibiga
            solinadi.
          </li>
        </ul>
      </div>
      <div className={styles.fourth}>
        <h3>4. Foydalanuvchilar tomonidan Servisga yuboriladigan axborotni qayta ishlash</h3>
        <ul>
          <li>
            1. Foydalanuvchilar tomonidan Servisga yuboriladigan axborot Servis operatori kabinetiga kelib tushadi..
          </li>
          <li>
            2. Foydalanuvchilar tomonidan Servisga yuboriladigan axborotni qayta ishlash natijalariga koʼra, Servis
            operatori quyidagi harakatni amalga oshiradi:
            <ol style={{listStyleType: 'square'}}>
              <li>Foydalanuvchilarning xabarini chop etadi;</li>
              <li>Foydalanuvchilardan signallarni qabul qiladi;</li>
              <li>Foydalanuvchilar tomonidan Servisga yuboriladigan axborotni rad etadi.</li>
            </ol>
          </li>
          <li>
            3. Ushbu Reglamentning 2.2. bandi ikkinchi xatboshida koʼrsatilgan harakatni amalga oshirgan taqdirda,
            Foydalanuvchilarning xabari majburiy tartibga chop qilinishi kerak hamda unga javob tayyorlash maqsadida
            masʼul ijrochilarga yuborish uchun vakolatli xodim shaxsiy kabinetiga kelib tushadi.
          </li>
          <li>
            4. Ushbu Reglamentning 2.2. bandi uchinchi xatboshida koʼrsatilgan harakat amalga oshirgan taqdirda
            Foydalanuvchilarning signali tekshiruv oʼtkazilishi uchun vakolatli vakolatli xodim shaxsiy kabinetiga
            yoʼllanishi shart. .
          </li>
          <li>
            5. Ushbu Reglamentning 2.2. bandi toʼrtinchi xatboshida koʼrsatilgan harakat amalga oshirgan taqdirda
            Foydalanuvchilarning elektron manzili boʼyicha Foydalanuvchilar tomonidan Servisga yuborilgan axborotni rad
            etish toʼgʼrisidagi xabarnoma yuboriladi va bunda Yagona moderatsiya qoidalari va yoki Kelishuvning tegishli
            bandi koʼrsatiladi.
          </li>
          <li>
            6. Аxborotni qayta ishlash muddati Foydalanuvchilar tomonidan Servisga axborot yuborilgan paytdan boshlab,
            Foydalanuvchilarning xabari Servisda chop etilishi, Foydalanuvchilarning signali qabul qilinishi va yoki
            Foydalanuvchilarning elektron manziliga Foydalanuvchilar tomonidan Servisga yuborilgan axborotning Yagona
            moderatsiya qoidalari va yoki Kelishuvga mos kelmaganligi uchun qaytarilishi toʼgʼrisidagi xabar yuborilgan
            paytgacha 24 soatdan oshmasligi kerak, ushbu Reglamentning 2.7 bandida koʼrsatilgan holatlar bundan
            mustasno..
          </li>
          <li>
            7. Ish kuni boʼlmagan kunlarning umumiy soni ikki va undan ortiq kunni tashkil etgan bayram kunlariga
            toʼgʼri kelgan taqdirda, Foydalanuvchilar tomonidan Servisga yuborilgan axborotni qayta ishlash muddati 72
            soatdan ortiq boʼlmagan muddatga uzaytirilishi mumkin.
          </li>
        </ul>
      </div>
      <div className={styles.fifth}>
        <h3>5. Masʼul xodimlar tomonidan tayyorlangan javoblarni qayta ishlash</h3>
        <ul>
          <li>1. Foydalanuvchilarning xabarini olgan masʼul ijrochilar 8 ish kuni davomida unga javob tayyorlaydi.</li>
          <li>
            2. Masʼul ijrochilar tomonidan tayyorlangan javob qayta ishlash uchun Servis operatoriga joʼnatiladi.
          </li>
          <li>
            3. Servis operatori masʼul ijrochilar tomonidan tayyorlangan javobni qayta ishlash natijalariga koʼra,
            quyidagi harakatlarni amalga oshiradi:
            <ol style={{listStyleType: 'square'}}>
              <li>Masʼul ijrochining javobini eʼlon qiladi</li>
              <li>Masʼul ijrochining javobini qayta koʼrib chiqish uchun yuboradi.</li>
            </ol>
          </li>
          <li>
            4. Mazkur Reglamentning 3.3. bandi ikkinchi xatboshida koʼrsatilgan harakat amalga oshirgan taqdirda, masʼul
            ijrochilarning javobi Portalda chop etilib, xabar muallifi shaxsiy kabinetiga joʼnatilishi lozim.
          </li>
          <li>
            5. Mazkur Reglamentning 3.3. bandi uchinchi xatboshida koʼrsatilgan harakat amalga oshirgan taqdirda, masʼul
            ijrochining javobi tegishliligi boʼyicha qayta koʼrib chiqish uchun qaytariladi va bunda Yagona moderatsiya
            qoidalari va yoki Kelishuvning tegishli bandi koʼrsatilishi lozim.
          </li>
          <li>
            6. Servis operatori tomonidan masʼul ijrochilarning javobini qayta ishlash muddati 24 soatdan oshmasligi
            kerak, ushbu Reglamentning 3.7 bandida koʼrsatilgan holatlar bundan mustasno
          </li>
          <li>
            7. Ish kuni boʼlmagan kunlarning umumiy soni ikki va undan ortiq kunni tashkil etgan bayram kunlariga
            toʼgʼri kelgan taqdirda, masʼul ijrochilar javobini qayta ishlash muddati 72 soatdan ortiq boʼlmagan
            muddatga uzaytirilishi mumkin.
          </li>
        </ul>
      </div>
      <div className={styles.sixth}>
        <h3>6. Tomonlarning huquq va majburiyatlari</h3>
        <ul>
          <li>
            1. Operator quyidagi majburiyatlarga ega:
            <p>
              Ushbu Kelishuvning 3.1. – 3.3. bandlariga muvofiq Foydalanuvchiga Servis servislaridan foydalanish
              imkoniyatini yaratish;
            </p>
            <p>
              Foydalanuvchi va uning Servisdagi harakatlari boʼyicha maʼlumotlarni oshkor qilmaslik va ularni uchinchi
              shaxslarga bermaslik, Oʼzbekiston Respublikasining amaldagi qonunchiligida koʼzda tutilgan holatlar bundan
              mustasno.
            </p>
          </li>
          <li>
            2. Operator quyidagi huquqlarga ega:
            <p>
              Istalgan paytda ushbu Kelishuv qoidalariga oʼzgartishlar kiritish va Foydalanuvchini bu haqda keyinchalik
              ogohlantirish;
            </p>
            <p>
              Servis servislariga oʼzgartishlar kiritish, shuningdek, yangi servislardan foydalanish imkoniyatini
              yaratish;
            </p>
            <p>
              Foydalanuvchi tomonidan ushbu Kelishuvda koʼzda tutilgan majburiyatlar buzilgan taqdirda Foydalanuvchiga
              uning xabarlari, signallari yoki takliflarini qayta ishlashni rad etish;
            </p>
            <p>
              Foydalanuvchining roziligi bilan, uning Servisda koʼzda tutilgan muammoli mavzularga oid boʼlmagan
              muammolar
              toʼgʼrisidagi xabarini Oʼzbekiston Respublikasining “Jismoniy va yuridik shaxslarning murojaatlari
              toʼgʼrisidagi Qonuni doirasida koʼrib chiqish uchun vakolatga ega boʼlgan Masʼul ijrochilarga yuborish;
            </p>
            <p>
              Foydalanuvchi tomonidan ushbu Kelishuv qoidalari buzilgan taqdirda istalgan davrga blokka tushirish yoki
              hisobga olish qaydini oʼchirib tashlash;
            </p>
            <p>
              Foydalanuvchining xatti-harakatlarini ushbu Kelishuv qoidalariga zid deb topish uchun asoslar mavjud
              boʼlgan
              taqdirda Foydalanuvchini istalgan davrga blokka tushirish yoki hisobga olish qaydini oʼchirib tashlash;
            </p>
            <p>
              Beodob iboralar, ijtimoiy dialekt, tushunarsiz qisqartmalar qoʼllanilgan xabarlar, shuningdek siyosiy
              mavzudagi signallar va takliflar yoki tijorat va boshqa tusdagi shiorlarni koʼzda tutuvchi xabarlar,
              signallar va takliflar koʼrib chiqilmaydi.
            </p>
          </li>
          <li>
            3. Operator uning aybi bilan roʼy bermagan, uchinchi shaxslar tomonidan Servis servislaridan noqonuniy
            foydalanish oqibatlari uchun javobgar hisoblanmaydi.
          </li>
          <li>
            4. Foydalanuvchi quyidagi majburiyatlarga ega:
            <p>
              Oʼz hisobidan Internetdan foydalanish, oʼz uskunalarini sozlash va ulardan noqonuniy foydalanishdan
              himoyalashni taʼminlash;
            </p>
            <p>
              Oʼzining hisobga olish qaydidan foydalanish, va Foydalanuvchi tomonidan belgilangan parolning saqlanishi
              uchun javobgar hisoblanadi;
            </p>
            <p>
              Servisda bittadan ortiq hisobga olish qaydini yaratmaslik va ulardan foydalanmaslik;
            </p>
            <p>
              Foydalanuvchi tomonidan roʼyxatdan oʼtish yoki avtorizatsiya bajarilganidan soʼng Servis orqali amalga
              oshirilgan barcha murojaatlar va xatti-harakatlar uchun masʼuliyatni oʼz zimmasiga olish;
            </p>
            <p>
              Servis servislaridan amaldagi qonunchilikka zid boʼlgan biror-bir xatti-harakatlarni amalga oshirish
              maqsadida foydalanmaslik, shuningdek, Servis servislarida noqonuniy, tahqirlovchi, tuhmatli, tajovuzkor
              yoki shovqin-suronli materiallarni joylashtirmaslik yoki Servis servislari orqali uzatmaslik;
            </p>
            <p>
              Servis va uning servislaridan foydalanish paytida beodob, haqoratli yoki qoʼpol iboralardan, shior va
              daʼvatlardan tashkil topgan iboralardan, tashkilotlar, harakatlar, tarixiy va yoki siyosiy shaxslar
              nomlaridan, hayotga, sogʼliq va mol-mulkka tahdidlarni koʼzda tutuvchi, ishbilarmonlik etikasi yoki
              ishbilarmonlik aylanmasi qoidalari va talablariga zid boʼlgan, fuqaro, yuridik shaxs, yoki davlat
              organining shaʼni yoki ishbilarmonlik obroʼsiga putur yetkazuvchi, shuningdek, kamsitish tusiga ega
              boʼlgan feʼllar yoki soʼz biriklalaridan foydalanmaslik;
            </p>
            <p>
              Shuningdek, Servisdan tashviqot, kamsitish, huquqlarni poymol qilish maqsadida, jamoat, siyosiy yoki
              tijoriy maqsadlarni ilgari surish uchun foydalanish taqiqlanadi;
            </p>
            <p>
              Boshqa shaxsning nomidan ish yuritish va buning uchun tegishli huquqqa ega boʼlmagan holda oʼzini
              tashkilot yoki jamoa vakili deb koʼrsatish, oʼzini ushbu Foydalanuvchi deb koʼrsatish uchun biron bir
              Foydalanuvchining nomidan foydalanish, shuningdek, boshqa shaxslar vakilligiga oid har qanday shakllar va
              usullardan foydalanmaslik;
            </p>
            <p>
              Boshqa Foydalanuvchi yoki axborotga ishlov berishning boshqa ishtirokchilari tomonidan Servisga yuborilgan
              materiallardan oʼz nomidan qayta Servisga joylashtirish uchun yoki boshqa maqsadlar uchun toʼplamaslik,
              saqlamaslik va ulardan foydalanmaslik;
            </p>
            <p>
              Servis servislaridan Servisning yaratilishi maqsadlariga zid boʼlgan maqsadlarda foydalanmaslik;
            </p>
            <p>
              Soxtaligi maʼlum boʼlib turgan, chalgʼituvchi, haqqoniy boʼlmagan yoki noaniq maʼlumotni, yoki spam deb
              toifalanishi mumkin boʼlgan, shuningdek Servisning boshqa Foydalanuvchisi yoki uchinchi shaxslarning
              manfaatlarini kamsituvchi, Servis hamda davlat organlari va tashkilotlari xodimlarni haqorat qiluvchi yoki
              boshqa sabablarga koʼra Servisda joylashtirilishi maqsadga muvofiq boʼlmagan maʼlumotlarni
              joylashtirmaslik;
            </p>
            <p>
              Servis servislaridan noqonuniy foydalanishga yoki ushbu Kelishuvda koʼrsatilgan qoidalarni chetlab oʼtgan
              holda foydalanishga urinmaslik;
            </p>
            <p>
              Imkon qadar muammo toʼgʼrisidagi xabar, signalni yoki taklifni xushmuomilalik bilan, qisqa va tushunish
              uchun qulay boʼlgan shaklda ifodalash;
            </p>
            <p>
              Foydalanuvchi tomonidan yuborilgan xabar va signal oʼzi istagan paytda koʼrib chiqishni toʼxtatish, uni
              qaytarib olish (rad qilish, koʼrmasdan qoldirishni talab qilish).
            </p>
          </li>
          <li>
            5. Servis servislaridan foydalanishda beriladigan imkoniyatlar bilan bogʼliq daʼvolar yoki istaklar
            tugʼilgan taqdirda, Oʼzbekiston Respublikasining amaldagi qonun hujjatlariga muvofiq murojaatnoma yozishi
            mumkin.
          </li>
          <li>
            6. Foydalanuvchi Servis servislaridan quyidagi maqsadlar uchun foydalanmaslikka oʼz roziligini beradi:
            <p>
              Mualliflik huquqlari va boshqa intellektual mulk huquqlarini buzadigan, irqiy, etnik, jinsiy, ijtimoiy
              xususiyatlariga koʼra odamlarga nafratni yoki ularni kamsitishini targʼib etuvchi materiallarni yuklab
              olish, joʼnatish, uzatish yoki boshqa bir usulda chop etish.
            </p>
            <p>
              Mazmuni Oʼzbekiston Respublikasining amaldagi qonunchiligiga zid boʼlgan havolalarni Internet tarmogʼi
              resurslarida joylashtirish.
            </p>
          </li>
        </ul>
      </div>
      <div className={styles.seventh}>
        <h3>
          7. Аxborot va materiallardan foydalanish va ularni saqlash.
        </h3>
        <ul>
          <li>
            1. Operator Foydalanuvchi tomonidan joylashtirilgan materiallarni saqlash muddati va eng koʼp soni boʼyicha
            cheklovlarni belgilashga haqli.
          </li>
          <li>
            2. Foydalanuvchi Operatorga u tomonidan Servisga yuborilgan materiallardan foydalanish, jumladan, tiklash,
            tarqatish, qayta ishlash, ommaviy namoyish etish va umumeʼtiborga havola qilish boʼyicha oddiy nomutlaq
            litsenziyani taqdim etadi..
          </li>
          <li>
            3. Foydalanuvchi istalgan paytda oʼzining hisobga olish qaydini oʼchirib tashlashi mumkin. Foydalanuvchi
            oʼzining hisobga olish qaydini oʼchirgan taqdirda, Operator Foydalanuvchi tomonidan joylashtirilgan
            kontentning arxiv nusxalarni saqlash huquqiga ega.
          </li>
          <li>
            4. Servisdan foydalanish Foydalanuvchining har qanday maʼlumotlaridan, shu jumladan, soz holatda ishlash va
            tizim servislaridan foydalanish boʼyicha statistik maʼlumotlarni toʼplash (shu jumladan, cookie fayllar)
            yoki Foydalanuvchi tomonidan mustaqil ravishda berilgan, jumladan, shaxsga doir maʼlumotlardan
            foydalanilishiga roziligini anglatadi.
          </li>
          <li>
            5. Foydalanuvchi tomonidan mustaqil ravishda berilgan shaxsga doir maʼlumotlarni ishlov berish Operator
            tomonidan Oʼzbekiston Respublikasining “Shaxsga doir maʼlumotlar toʼgʼrisida”gi Qonuniga va axborot tizimiga
            qoʼyiladigan talablarga muvofiq amalga oshiriladi.
          </li>
          <li>
            6. Foydalanuvchi "Faol fuqaro" Servisiga xizmat bilan oʼzaro aloqaning har qanday bosqichida mustaqil
            ravishda kiritilgan maʼlumotlarni: ismi, jinsi, tugʼilgan sanasi, yashash manzili, pasportning seriya raqami
            va jismoniy shaxsning shaxsiy identifikatsiya raqami, foydalanuvchining fotosurati, aloqa telefon raqami;
            qabul qiladi va qayta ishlab chiqadi.
          </li>
          <li>
            7. Xizmat jismoniy shaxsning huquqiy layoqatini tekshirish imkoniyatiga ega emas, shuningdek jismoniy
            shaxs tomonidan taqdim yetilgan maʼlumotlarni tekshirmaydi. "Faol fuqaro" xizmati shaxs oʼzi haqida yetarli,
            ishonchli va dolzarb maʼlumotlarni taqdim yetganidan kelib chiqadi.
          </li>
          <li>
            8. “Xalq nazarati” tizimi faqatgina xizmat koʼrsatish yoki foydalanuvchi bilan tuzilgan shartnomalarni
            bajarish uchun zarur boʼlgan shaxsiy maʼlumotlarni toʼplaydi va saqlaydi. Qonun hujjatlarida ayrim shaxsiy
            maʼlumotlarni majburiy olish va saqlash nazarda tutilgan hollar bundan mustasno. Shaxsiy maʼlumotlarni
            saqlash, agar bunday shaxsiy maʼlumotlarni saqlash muddati Oʼzbekiston Respublikasi qonunchiligida
            foydalanuvchi tomonidan "Faol fuqaro"dan foydalanish boʼyicha siyosatda belgilanmagan boʼlsa, qayta ishlash
            maqsadlarida belgilangan muddatda amalga oshiriladi. “Faol fuqaroi” xizmati foydalanuvchining shaxsiy
            maʼlumotlaridan shaxsiylashtirilgan maʼlumotlarga asoslangan holda statistik va boshqa tadqiqotlar oʼtkazish
            uchun ishlatilishi mumkin.
          </li>
        </ul>
      </div>
      <div className={styles.eighth}>
        <h3>
          8. Ushbu Kelishuv qoidalarining buzilishi.
        </h3>
        <ul>
          <li>
            1. Foydalanuvchi ushbu Kelishuvda koʼzda tutilgan shartlarni buzgan taqdirda va Servisning amal qilishi har
            qanday sababga koʼra toʼxtatilganda, Operator Foydalanuvchini oldindan ogohlantirmagan holda hisobga olish
            qaydini har qanday muddatga bloklab qoʼyish yoki oʼchirib tashlash huquqiga ega.
          </li>
          <li>
            2. Operator Foydalanuvchini oldindan ogohlantirmagan holda quyidagi hollarda Servis servislaridan
            foydalanishni cheklab qoʼyishi mumkin:
            <ol style={{listStyleType: "square"}}>
              <li>
                Foydalanuvchi tomonidan ushbu Kelishuv shartlari buzilgan taqdirda;
              </li>
              <li>
                Operator servislariga turli toʼsiqlar va buzilishlar yaratilganda, shu jumladan, har qanday uskunalar,
                dasturiy taʼminotlardan foydalanilgan hollarda.
              </li>
            </ol>
          </li>
          <li>
            3. Operator Foydalanuvchi tomonidan ushbu Kelishuvning har qanday sharti buzilgan taqdirda u yoki uchinchi
            shaxslar oldida javobgar hisoblanmaydi.
          </li>
        </ul>
      </div>
      <div className={styles.nineth}>
        <h3>9. Boshqa shartlar.</h3>
        <ul>
          <li>
            1. Ushbu Kelishuv shartlari tomonlardan biri oʼzining Kelishuvdan chiqish istagini maʼlum qilmaguncha amal
            qiladi.
          </li>
          <li>
            2. Foydalanuvchi oʼzining profilini oʼchirib tashlash boʼyicha maxsus interfeysdan foydalangan holda
            Servisdan va Operatorning boshqa servislaridan foydalanishdan bosh tortishga haqli.
          </li>
          <li>
            3. Ushbu Kelishuv boʼyicha kelib chiqishi mumkin boʼlgan barcha nizolar Oʼzbekiston Respublikasining
            amaldagi qonunchiligiga muvofiq hal etiladi.
          </li>
        </ul>
      </div>
    </div>
  );
}

export default PrivacyPolicy;